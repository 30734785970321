import { pad } from '@ultraq/promise-utils';

/**
 * Pad a route handler so that it will take at least some specified time to
 * complete.  Used to create minimum transition times to work with the route
 * change animation.
 * 
 * @template T
 * @param {Promise<T>} action
 * @return {Promise<T>}
 */
export function padForRoute(action) {
	return pad(() => action, 400);
}
