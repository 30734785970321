import UTextProcessor from './ifnotnull/UTextProcessor.js';

import { Dialect } from 'thymeleaf';

export const NAME           = 'If-Not-Null';
export const DEFAULT_PREFIX = 'ifnotnull';

/**
 * Class emulating the thymeleaf-ifnotnull-dialect library.
 */
export default class IfNotNullDialect extends Dialect {

	/**
	 * Constructor, sets the prefix for the dialect.
	 * 
	 * @param {string} [prefix]
	 * @param {object} [isomorphic]
	 */
	constructor(prefix = DEFAULT_PREFIX, isomorphic) {

		super(NAME, prefix);
		this.isomorphic = isomorphic;
	}

	/**
	 * Returns the supported processors.
	 * 
	 * @return {Array} A list of the processors included in this dialect.
	 */
	get processors() {

		let { prefix, isomorphic } = this;

		return [
			new UTextProcessor(prefix, isomorphic)
		];
	}
}
