import FragmentProcessor from './layout/FragmentProcessor.js';

import { Dialect } from 'thymeleaf';

export const NAME           = 'Layout';
export const DEFAULT_PREFIX = 'layout';

/**
 * This layout dialect does nothing except to remove the `layout:*` entries from
 * the template.
 */
export default class LayoutDialect extends Dialect {

	/**
	 * Constructor, set the name and prefix for this dialect.
	 * 
	 * @param {string} [prefix]
	 */
	constructor(prefix = DEFAULT_PREFIX) {

		super(NAME, DEFAULT_PREFIX);
	}

	/**
	 * Returns the supported processors.
	 * 
	 * @return {Array} A list of the processors included in this dialect.
	 */
	get processors() {

		let { prefix } = this;

		return [
			new FragmentProcessor(prefix)
		];
	}
}
