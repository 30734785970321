
import { $$ } from 'dumb-query-selector';

/**
 * Substitute the special tokens in a no-spam address for their functional
 * e-mail counterparts.
 */
export function fixNoSpamAddresses() {
	$$("a[rel='email']").forEach(element => {
		element.href = element.href
			.replace(/\[AT\]/, '@')
			.replace(/\[DOT\]/g, '.');
	});
}
