import App from './App.js';
import { createTemplateEngine } from './scaffolding/TemplateEngine.js';
import { fixNoSpamAddresses } from './utilities/Emails.js';

import { createBrowserHistory } from 'history';
import { parseJsonFromElement } from '@ultraq/dom-utils';

/**
 * Entry point for the MooCow website, sets up client-side Thymeleaf to take
 * over after server-side Thymeleaf has given us our first page.
 */

fixNoSpamAddresses();

new App(
	createBrowserHistory(),
	createTemplateEngine(parseJsonFromElement('#data'))
);
