import fetchPost from './fetchPost.js';
import { padForRoute } from '../../utilities/Routing.js';
import { isAuthenticated } from '../../utilities/Security.js';

/**
 * Build a route handler for visiting a blog post by it's URI.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => ({ state }, [, postUri]) => {
	return padForRoute(Promise.all([
		import('../../views/Post.js'),
		fetchPost(postUri)
	]))
		.then(([{ default: Post }, post]) => {
			return Post(post, {
				...state,
				editable: isAuthenticated()
			}, history, templateEngine);
		});
};
