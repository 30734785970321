import { remove } from '@ultraq/array-utils';
import { pathToRegexp } from 'path-to-regexp';

/**
 * A class to manage regular expressions of paths and a function to execute when
 * those regexes are matched.
 */
export default class Router {

	currentPath = window.location.pathname;
	routeListeners = [];

	/**
	 * Constructor, set the map of route path strings and their handler functions.
	 *
	 * @param {object} history
	 * @param {object} routes
	 *   An object of route URL strings to a function that is invoked for that
	 *   path.  The function is called with the path variables of the current
	 *   route that matched it.
	 */
	constructor(history, routes) {

		// Convert the route object to a map for easier iteration
		this.routes = new Map(Object.entries(routes).map(([pathString, handler]) => {
			return [pathToRegexp(pathString), handler];
		}));

		// Add a route handler which will go through the registered routes and
		// invoke the first matching one
		this.routeListeners.push((location, decodedPathname) => {
			for (let [regex, handler] of this.routes) {
				let result = regex.exec(decodedPathname);
				if (result) {
					handler(location, result);
					break;
				}
			}
		});

		// Invoke all registered route listeners when the route changes
		history.listen(({ location }) => {
			this.currentPath = decodeURIComponent(location.pathname);
			this.routeListeners.forEach(routeListener => {
				routeListener(location, this.currentPath);
			});
		});
	}

	/**
	 * Add a change listener to be invoked when the route changes.
	 *
	 * @param {Function} listener
	 *   Function to invoke with the new location object when the route changes.
	 * @return {Function}
	 *   A function that can be called to unsubscribe the listener from route
	 *   changes.
	 */
	subscribe(listener) {

		this.routeListeners.push(listener);
		return () => {
			remove(this.routeListeners, routeListener => routeListener === listener);
		};
	}
}
