import fetchPostsForCategory from './fetchPostsForCategory.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for visiting a list of posts by their category.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => (location, [, category]) => {
	return padForRoute(Promise.all([
		import('../../views/Posts.js'),
		fetchPostsForCategory(category)
	]))
		.then(([{ default: Posts }, posts]) => {
			return Posts(posts, category, templateEngine);
		});
};
