
/**
 * Return whether or not this item has a hero image associated with it.
 * 
 * @param {object} item
 * @return {boolean} `true` if the item has a hero image path.
 */
export function hasHeroImage(item) {
	return item && item.heroImage;
}

/**
 * Return whether or not this item has a preview image associated with it.
 * 
 * @param {object} item
 * @return {boolean} `true` if the item has a hero image path.
 */
export function hasPreviewImage(item) {
	return item && item.previewImagePath;
}
