import fetchWritings from './fetchWritings.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for viewing a list of all writing items.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => () => {
	return padForRoute(Promise.all([
		import('../../views/Writings.js'),
		fetchWritings()
	]))
		.then(([{ default: Writings }, writings]) => {
			return Writings(writings, templateEngine);
		});
};
