import { $ } from 'dumb-query-selector';

/**
 * Return the CSRF header name embedded in the page.
 * 
 * @return {string}
 */
export function csrfHeaderName() {
	return $('meta[name="csrf-header-name"]').getAttribute('content');
}

/**
 * Return the CSRF token embedded in the page.
 * 
 * @return {string}
 */
export function csrfToken() {
	return $('meta[name="csrf-token"]').getAttribute('content');
}

/**
 * Performs a standard `fetch` request but with CSRF headers added.
 *
 * @template T
 * @param {string} url
 * @param {object} options
 * @return {Promise<T>}
 */
export function fetchWithCsrf(url, options) {
	return fetch(url, {
		...options,
		headers: {
			[csrfHeaderName()]: csrfToken(),
			...options.headers
		}
	});
}

/**
 * Return whether the current user is logged in or not.
 * 
 * @return {boolean}
 */
export function isAuthenticated() {
	return $('meta[name="isAuthenticated"]')?.content === 'true';
}
