import { AttributeProcessor } from 'thymeleaf';

export const NAME = 'utext';

/**
 * Processor for emitting unescaped text if present, removing the element if
 * not.
 */
export default class UTextProcessor extends AttributeProcessor {

	/**
	 * Constructor, create this processor with the given prefix.
	 * 
	 * @param {string} prefix
	 * @param {object} [isomorphic]
	 */
	constructor(prefix, isomorphic) {

		super(prefix, NAME, isomorphic);
	}

	/**
	 * Remove the element or emit unescaped text within it, using the existence of
	 * the value at the end of the expression for it.
	 * 
	 * @param {Element} element
	 *   Element being processed.
	 * @param {string} attribute
	 *   The attribute that was encountered to invoke this processor.
	 * @param {string} attributeValue
	 *   The value given by the attribute.
	 * @param {object} context
	 * @return {boolean} `true` when the element has been removed and so
	 *   reprocessing behaviour should occur.
	 */
	process(element, attribute, attributeValue, context) {

		super.process(element, attribute, attributeValue, context);

		let output = context.expressionProcessor.process(attributeValue, context);
		if (output) {
			element.innerHTML = output;
			return false;
		}
		else {
			element.parentElement.removeChild(element);
			return true;
		}
	}
}
