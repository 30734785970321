import fetchArtwork  from './fetchArtwork.js';
import { padForRoute } from '../../utilities/Routing.js';

import { TemplateEngine } from 'thymeleaf';

/**
 * Build a route handler for viewing an artwork by its URI.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => (location, [, artworkUri]) => {
	return padForRoute(Promise.all([
		import('../../views/Artwork.js'),
		fetchArtwork(artworkUri)
	]))
		.then(([{ default: Artwork }, artwork]) => {
			return Artwork(artwork, templateEngine);
		});
};
