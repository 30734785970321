import { checkStatus, responseAsJson } from '@ultraq/fetch-utils';

/**
 * Fetch a list of posts from the server that are tagged with the given
 * category.
 * 
 * @param {string} category
 * @return {Promise}
 */
export default function(category) {
	return fetch(`/api/blog/category/${category}`, {
		headers: {
			'Accept': 'application/json'
		}
	})
		.then(checkStatus)
		.then(responseAsJson);
}
