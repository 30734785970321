import fetchLatestPost from './fetchLatestPost.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for going to the latest post.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => ({ state }) => {
	return padForRoute(Promise.all([
		import('../../views/Post.js'),
		fetchLatestPost()
	]))
		.then(([{ default: Post }, post]) => {
			return Post(post, {
				...state
			}, history, templateEngine);
		});
};
