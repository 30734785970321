import { checkStatus, responseAsJson } from '@ultraq/fetch-utils';

/**
 * Fetch the writing from the server with the given URI.
 * 
 * @param {string} writingUri
 * @return {Promise}
 */
export default function(writingUri) {
	return fetch(`/api/writing/${writingUri}`, {
		headers: {
			'Accept': 'application/json'
		}
	})
		.then(checkStatus)
		.then(responseAsJson);
}
