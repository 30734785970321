import fetchNewPost from './fetchNewPost.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for going to the URL that creates a new blog post for
 * editing.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => () => {
	return padForRoute(Promise.all([
		import('../../views/Post.js'),
		fetchNewPost()
	]))
		.then(([{ default: Post }, post]) => {
			return Post(post, {
				editable: true,
				editing: true
			}, history, templateEngine);
		});
};
