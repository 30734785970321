
/**
 * Special value for knowing to emit a machine-readable date string in the
 * {@link #format} method.
 * 
 * @type {string}
 */
export const MACHINE = 'MACHINE';

/**
 * Format a date using the specified formatting pattern.
 * 
 * @param {Date|string} date
 * @param {object} [options]
 * @return {string}
 */
export function format(date, options = {}) {

	if (!date) {
		return '';
	}

	// The date from a JSON object is an ISO8601 string
	return options === MACHINE ?
		typeof date === 'string' ? date : date.toISOString() :
		(typeof date === 'string' ? new Date(date) : date).toLocaleDateString('en-NZ', options);
}
