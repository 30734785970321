import { padForRoute } from '../utilities/Routing.js';

import { TemplateEngine } from 'thymeleaf';

/**
 * Build a route handler for going to the About page.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => () => {
	return padForRoute(import('../views/About.js'))
		.then(({ default: About }) => {
			return About(templateEngine);
		});
};
