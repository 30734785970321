import goToArtwork from './actions/artwork/goToArtwork.js';
import goToArtworks from './actions/artwork/goToArtworks.js';
import goToArtworksForCategory from './actions/artwork/goToArtworksForCategory.js';
import goToLatestPost from './actions/blog/goToLatestPost.js';
import goToNewPost from './actions/blog/goToNewPost.js';
import goToPost from './actions/blog/goToPost.js';
import goToPostsForCategory from './actions/blog/goToPostsForCategory.js';
import goToAbout from './actions/goToAbout.js';
import goToServer from './actions/goToServer.js';
import goToWriting from './actions/writing/goToWriting.js';
import goToWritings from './actions/writing/goToWritings.js';
import Router from './scaffolding/Router.js';
import { html } from './utilities/Html.js';
import { isAuthenticated } from './utilities/Security.js';
import { supportsParallaxEffect } from './utilities/Support.js';

import { addEventDelegate } from '@ultraq/dom-utils';
import { $ } from 'dumb-query-selector';
import { TemplateEngine } from 'thymeleaf';

/**
 * Main controller for the MooCow app.
 */
export default class App {

	/**
	 * Start the app with the given components.
	 *
	 * @param {History} history
	 * @param {TemplateEngine} templateEngine
	 */
	constructor(history, templateEngine) {

		// Apply router for handling URL changes
		new Router(history, this.buildRoutes(history, templateEngine));

		// Enable client-side routing for all local links
		addEventDelegate(document.body, 'click', 'a[href^="/"], a[href^="/"] img', event => {
			let href = event.target.closest('a').getAttribute('href');

			// Make an exception for the /feed URL
			if (href !== '/feed') {
				event.preventDefault();
				history.push(href);
			}
		});

		// Enable client-side editing of items
		if (isAuthenticated()) {
			addEventDelegate(document.body, 'click', '#edit', event => {
				if (!event.defaultPrevented) {
					// Reload the page in editing state to get control in the client
					history.replace(window.location.pathname, {
						editing: true
					});
				}
			});
		}
	}

	/**
	 * Return the collection of default routes and their associated data-retrieval
	 * actions.
	 *
	 * @private
	 * @param {History} history
	 * @param {TemplateEngine} templateEngine
	 * @return {object}
	 */
	buildRoutes(history, templateEngine) {

		let routeElements = [
			$('.moocow-parallax--layer'),
			$('.moocow-content'),
			$('.moocow-sitefooter')
		];
		routeElements.forEach(routeElement => routeElement.classList.add('moocow-route'));

		/*
		 * Wraps a route handler to include the adding and removing the app loading
		 * state on route change and after content update respectively, creating the
		 * animated transitions throughout the website.
		 *
		 * @param {Function<Promise>} routeHandler
		 * @return {Function<Promise>}
		 */
		const withRouteTransition = (routeHandler) => (...args) => {
			let canParallax = supportsParallaxEffect();
			if (canParallax) {
				$('.moocow-parallax--base').scrollTo(0, 0);
			}
			document.body.appendChild(html`
				<div class="moocow-loading moocow-loading-delay-for-route">
					Loading...
				</div>
			`);
			routeElements.forEach(routeElement => routeElement.classList.add('moocow-route-before'));

			return routeHandler(...args)
				.then(result => {
					if (!canParallax) {
						window.scrollTo(0, 0);
					}
					$('.moocow-loading').remove();
					routeElements.forEach(routeElement => routeElement.classList.add('moocow-route-after'));
					setTimeout(() => {
						routeElements.forEach(routeElement => {
							routeElement.classList.remove('moocow-route-before');
							routeElement.classList.remove('moocow-route-after');
						});
					}, 400);
					return result;
				});
		};

		return Object.entries({
			'/blog/category/:category':    goToPostsForCategory,
			'/blog/new':                   goToNewPost,
			'/blog/:postUri':              goToPost,
			'/blog/':                      goToLatestPost,
			'/artwork/category/:category': goToArtworksForCategory,
			'/artwork/:artworkUri':        goToArtwork,
			'/artwork/':                   goToArtworks,
			'/writing/:writingUri':        goToWriting,
			'/writing/':                   goToWritings,
			'/about':                      goToAbout,
			'/all/':                       goToServer,
			'/':                           goToLatestPost,

			// A catch-all for unhandled routes, goes to the server and usually results in 404
			'/(.*)': goToServer
		})
			.reduce((acc, [path, handler]) => {
				acc[path] = withRouteTransition(handler(history, templateEngine));
				return acc;
			}, {});
	}
}
