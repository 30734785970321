import { deserialize } from '@ultraq/dom-utils';
import { $ } from 'dumb-query-selector';

/**
 * A template literal tag function, takes an HTML string that can include
 * function expressions for event handler attributes defined in the string.
 * 
 * @param {Array<string>} strings
 * @param {Array<*>} expressions
 * @return {Element}
 */
export function html(strings, ...expressions) {

	let htmlString = '';
	let eventHandlers = [];

	// Build the HTML string, putting markers for event handlers as we go
	for (let i = 0; i < strings.length; i++) {
		let string = strings[i];
		htmlString += string;
		let expression = expressions?.[i];

		// An event handler
		if (expression && typeof expression === 'function') {

			// Figure out what kind of event is to be listened for and put some
			// placeholder text there to pick it out later.
			let [, eventName] = /.*\son(.+)="/g.exec(string);
			let marker = `${eventName}-${i}`;

			htmlString += `'${marker}'`;
			eventHandlers.push({
				eventHandler: expression,
				eventName,
				marker
			});
		}

		// Put anything else verbatim
		else {
			htmlString += expression;
		}
	}

	// Work with the full doc first
	let doc = deserialize(htmlString);

	// Attach the event handlers to their respective elements
	for (let j = 0; j < eventHandlers.length; j++) {
		let { eventHandler, eventName, marker } = eventHandlers[j];
		let attribute = `on${eventName}`;
		let element = $(`[${attribute}="'${marker}'"]`, doc);
		element.removeAttribute(attribute);
		element.addEventListener(eventName, eventHandler);
	}

	// Extract the fragment
	return doc.body.firstElementChild;
}
