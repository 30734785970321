import fetchWriting from './fetchWriting.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for viewing a writing item by its URI.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => (location, [, writingUri]) => {
	return padForRoute(Promise.all([
		import('../../views/Writing.js'),
		fetchWriting(writingUri)
	]))
		.then(([{ default: Writing }, writing]) => {
			return Writing(writing, templateEngine);
		});
};
