
import { checkStatus, responseAsJson } from '@ultraq/fetch-utils';

/**
 * Fetch the latest post from the server.
 * 
 * @return {Promise}
 */
export default function() {
	return fetch(`/api/blog/latest`, {
		headers: {
			'Accept': 'application/json'
		}
	})
		.then(checkStatus)
		.then(responseAsJson);
}
