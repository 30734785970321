
import { checkStatus, responseAsJson } from '@ultraq/fetch-utils';

/**
 * Fetch the post from the server with the given ID.
 * 
 * @param {string} postId
 * @return {Promise}
 */
export default function(postId) {
	return fetch(`/api/blog/${postId}`, {
		headers: {
			'Accept': 'application/json'
		}
	})
		.then(checkStatus)
		.then(responseAsJson);
}
