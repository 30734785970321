import fetchArtworksForCategory from './fetchArtworksForCategory.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for viewing a list of all artwork by a category.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => (location, [, category]) => {
	return padForRoute(Promise.all([
		import('../../views/Artworks.js'),
		fetchArtworksForCategory(category)
	]))
		.then(([{ default: Artworks }, artworks]) => {
			return Artworks(artworks, category, templateEngine);
		});
};
