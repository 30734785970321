import { format, MACHINE } from '../utilities/Dates.js';
import { hasHeroImage, hasPreviewImage } from '../utilities/Items.js';

import { join } from '@ultraq/url-utils';
import { marked } from 'marked';
import { Dialect } from 'thymeleaf';

export const NAME = 'MooCow';

/**
 * The website dialect, sets up additional expression objects to matche those on
 * the server side.
 */
export default class MooCowDialect extends Dialect {

	/**
	 * Constructor, set the name for the dialect.
	 * 
	 * @param {object} options
	 * @param {string} options.imagesBaseUrl
	 * @param {string} options.imagesGeneratedThumbnailBaseUrl
	 * @param {string} options.imagesOptimizedBaseUrl
	 * @param {string} options.imagesOptimizedContentBaseUrl
	 * @param {string} options.imagesOptimizedHeroBaseUrl
	 */
	constructor({ imagesBaseUrl, imagesGeneratedThumbnailBaseUrl, imagesOptimizedBaseUrl, imagesOptimizedContentBaseUrl, imagesOptimizedHeroBaseUrl }) {

		super(NAME);

		this.imagesBaseUrl = imagesBaseUrl;
		this.imagesGeneratedThumbnailBaseUrl = imagesGeneratedThumbnailBaseUrl;
		this.imagesOptimizedBaseUrl = imagesOptimizedBaseUrl;
		this.imagesOptimizedContentBaseUrl = imagesOptimizedContentBaseUrl;
		this.imagesOptimizedHeroBaseUrl = imagesOptimizedHeroBaseUrl;
	}

	/**
	 * @inheritdoc
	 */
	get expressionObjects() {

		return {
			'#content': {
				markdownToHtml: content => {
					return marked.parse(content);
				},
				useOptimizedImageUrls: content => {
					return content
						.replace(new RegExp(`src="${this.imagesBaseUrl}(.*?.(gif|jpg|png))"`, 'g'), (match, imagePath) => {
							return `src="${this.imagesOptimizedContentBaseUrl}${imagePath}"`;
						})
						.replace(new RegExp(`\\!\\[(.*?)\\]\\(${this.imagesBaseUrl}(.*?\\).(gif|jpg|png))`, 'g'), (match, altText, imagePath) => {
							return `![${altText}](${this.imagesOptimizedContentBaseUrl}${imagePath})`;
						});
				}
			},
			'#dateFormat': {
				HUMAN: {
					day: 'numeric',
					month: 'long',
					weekday: 'long',
					year: 'numeric'
				},
				MACHINE
			},
			'#items': {
				hasHeroImage,
				hasPreviewImage
			},
			'#temporals': {
				format
			},
			'#urls': {
				artworkWallpaperUrl: (wallpaper, resolution) => {
					return join(this.imagesBaseUrl, 'artwork', wallpaper.gallery.toLowerCase(), `${wallpaper.uri}_${resolution}.jpg`);
				},
				heroImageUrl: (item, optimized = true) => {
					return item.heroImagePath ?
						join(optimized ? this.imagesOptimizedHeroBaseUrl : this.imagesBaseUrl, item.heroImagePath) :
						null;
				},
				previewImageUrl: item => {
					return item.useGeneratedPreviewImage ?
						item.heroImagePath ?
							join(this.imagesGeneratedThumbnailBaseUrl, item.heroImagePath) :
							null :
						item.previewImagePath ?
							join(this.imagesOptimizedBaseUrl, item.previewImagePath) :
							null;
				}
			}
		};
	}
}
