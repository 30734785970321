import { TemplateEngine } from 'thymeleaf';

/**
 * Build a route handler for hitting the server with whatever URL is given.
 * Used for the **everything** page, but any other route that could result in an
 * appropriate error page, like a 404.
 *
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => ({ pathname }) => {
	return new Promise(() => {
		window.location.href = pathname;
	});
};
