import IfNotNullDialect from '../thymeleaf/IfNotNullDialect.js';
import LayoutDialect from '../thymeleaf/LayoutDialect.js';
import MooCowDialect from '../thymeleaf/MooCowDialect.js';
import ItemDateTimeTemplate from '../../../../moocow-website/source/templates/fragments/Item-DateTime.html';
import ItemMetadataTemplate from '../../../../moocow-website/source/templates/fragments/Item-Metadata.html';
import PreviewTemplate from '../../../../moocow-website/source/templates/fragments/Preview.html';

import { STANDARD_CONFIGURATION, StandardDialect, TemplateEngine } from 'thymeleaf';

/**
 * Create and configure the template engine for the MooCow website.
 *
 * @param {object} config
 * @return {TemplateEngine}
 */
export function createTemplateEngine(config) {
	return new TemplateEngine({
		...STANDARD_CONFIGURATION,
		dialects: [
			new StandardDialect('th'),
			new IfNotNullDialect(),
			new LayoutDialect(),
			new MooCowDialect(config)
		],
		templateResolver: templateName => {
			switch (templateName) {
				case 'fragments/Item-DateTime': return ItemDateTimeTemplate;
				case 'fragments/Item-Metadata': return ItemMetadataTemplate;
				case 'fragments/Preview':       return PreviewTemplate;
			}
			throw new Error('Unknown fragment name');
		}
	});
}
