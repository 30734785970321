
/**
 * Return whether or not the browser supports parallax scrolling.  This is done
 * as a combination of a non-touch scroll device and if `prefers-reduced-motion`
 * isn't set to `reduce`.
 * 
 * @return {boolean}
 */
export function supportsParallaxEffect() {
	return window.matchMedia('(hover: hover) and (prefers-reduced-motion: no-preference)').matches;
}
