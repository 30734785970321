
import { fetchWithCsrf } from '../../utilities/Security.js';

import { checkStatus, responseAsJson } from '@ultraq/fetch-utils';

/**
 * Fetch a new blank post from the server.
 * 
 * @return {Promise}
 */
export default function() {
	return fetchWithCsrf(`/api/blog/new`, {
		headers: {
			'Accept': 'application/json'
		}
	})
		.then(checkStatus)
		.then(responseAsJson);
}
