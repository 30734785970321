import { AttributeProcessor } from 'thymeleaf';

export const NAME = 'fragment';

/**
 * Does nothing but to remove the `layout:fragment` attribute.
 */
export default class FragmentProcessor extends AttributeProcessor {

	/**
	 * Constructor, creates this processor with the given prefix.
	 * 
	 * @param {string} prefix
	 */
	constructor(prefix) {

		super(prefix, NAME);
	}
}
