import fetchArtworks from './fetchArtworks.js';
import { padForRoute } from '../../utilities/Routing.js';

/**
 * Build a route handler for visting a page of all artworks.
 * 
 * @param {History} history
 * @param {TemplateEngine} templateEngine
 * @return {Function}
 */
export default (history, templateEngine) => () => {
	return padForRoute(Promise.all([
		import('../../views/Artworks.js'),
		fetchArtworks()
	]))
		.then(([{ default: Artworks }, artworks]) => {
			return Artworks(artworks, 'all', templateEngine);
		});
};
